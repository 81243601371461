<style scoped lang="less">
  .person-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .person-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // padding: 10px 20px;
    // align-items: center;
    background-color: #FFF;
    // display: flex;
    // justify-content: space-between;
  }
  .person-lists {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #FFF;
    height: 0;
    flex: 1;
    position: relative;
  }
.img-c {
  height: 144px;
  width: 105px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 144px;
    max-width: 105px;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
.edu-his {
  width: 80%;
  border-spacing: 1px;
  border-collapse: collapse;
  border: 1px solid #E8E8E8;
  background-color: #FFF;
  tr:first-of-type, td:first-of-type {
    background: #FAFAFA;
  }
  td {
    color: rgba(0, 0, 0, 0.45);
    border: 1px solid #E8E8E8;
    padding: 5px 8px;
  }
}
.is-history {
  display: flex;
  align-items: center;
  color: #F4628F;
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #F4628F;
    margin-right: 5px;
  }
}
</style>

<template>
  <div class="person-page" v-loadingx="loading.lists">
    <div class="person-header">
      <fm-title :title-text="title || '员工信息管理'" :note-text="noteText" @clickTitleMenu="clickTitleMenu" :title-menus="titleMenus">
        <div v-if="isHistory" class="is-history">
          当前数据为历史数据
          <fm-date-picker @change="loadData" style="margin-left: 30px;width: 170px;" v-model="historyDate" type="date"></fm-date-picker>
        </div>
      </fm-title>
    </div>
    <div class="person-lists">
      <fm-table-new
        ref="table"
        :simple-filter="true"
        :full="true"
        auto-height
        :empty-search="emptySearch"
        :toolbox="showToolBox"
        :export-file-name="exportFileName"
        border="row"
        size="large"
        :stripe="false"
        emptyPlaceholder="-"
        :columns="columns"
        :data-list="dataList"
        @counterChange="countDataChange"
        :handler-counter="countFun"
      ></fm-table-new>
    </div>
    <fm-modal :mask-closable="true" v-model="sortModal" v-if="sortModal" width="800px" title="默认排序设置" theme="mh-blackt">
      <div style="width: 100%;height: 60vh;">
      <fm-table-new
        ref="table"
        :simple-filter="true"
        :full="true"
        auto-height
        border="row"
        :defaultOptionsFilterMethod="defaultOptionsFilterMethod"
        size="small"
        :stripe="false"
        emptyPlaceholder="-"
        :columns="columnsSort"
        :data-list="dataList"
      ></fm-table-new>
      </div>
    </fm-modal>
    <fm-modal :mask-closable="false" v-model="modal" v-if="modal" width="1100px" title="员工信息" theme="mh-blackt">
      <worker-form
        :source-data="choose"
        :fun-auth="{
          edit: funAuth.edit,
          add: funAuth.add
        }"
        :read-only="isRead"
        ref="worker_form"></worker-form>
      <div class="modal-footer-btns" v-if="!isRead">
        <!-- <fm-btn @click="saveModal('sq')" :v-loadingx="loading.modal" v-if="funAuth.applyWorkerAdd || funAuth.applyWorkerEdit">申请{{choose && choose.id ? '修改' : '添加'}}</fm-btn> -->
        <fm-btn @click="saveModal" :v-loadingx="loading.modal" v-if="funAuth.edit || funAuth.add">确定</fm-btn>
        <fm-btn @click="closeModal">取消</fm-btn>
      </div>
    </fm-modal>
    <resume v-if="choose && funAuth.resume" :positionMap="positionMap" :edu-his-data="eduHisdData" v-model="resume" :data="choose" @cancel="resume = false" :photo-src="src"></resume>
    <data-apply-log v-model="showEditLog" type="worker" :show-tool-box="showToolBox" :data-columns="columnsLog" />
    <fm-modal title="员工信息明细" :mask-closable="true" v-model="showDetail" width="80%" v-if="showDetail">
      <allInfo :worker-id="choose.id" :history-date="isHistory ? historyDate : null"></allInfo>
    </fm-modal>
    <A4Print ref="print" />
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { workerRequest, workerEduRequest, fileRequest, workerJobTitleRequest, dataAppleRequest } from '@/api'
import Resume from './resume'
import allInfo from '../person/info/allInfo'

import SortNum from './worker/sortNum'

import WorkerForm from './worker/workerForm'

import columnsLogs from '@/components/base/data-apply/columns'

import DataApplyLog from '@/components/base/data-apply/modal'

import A4Print from '@/components/a4print/index'

import {
  getAge
} from '@/syslib/tools'

import { loadResourcesByCache } from '@/components/base/file/libv2'


async function getImage (ids) {
  if (!ids || (Array.isArray(ids) && !ids.length)) {
    return []
  }

  const files = await fileRequest.getFileData({ ids: Array.isArray(ids) ? ids.join(',') : ids })
  const promise = files.map(file => {
    return loadResourcesByCache({ file, type: 'blob' })
  })

  return Promise.all(promise)
}

export default {
  props: {
    funAuthOther: Object,
    title: {
      type: String
    },
    jobTypeC: {
      type: Array
    },
    isDiscipline: {
      type: String
    }
  },
  components: { Resume, allInfo, DataApplyLog, WorkerForm, A4Print },
  data () {
    return {
      sortModal: false,
      orgMap: {},
      emptySearch: '__null',
      orgTree: [],
      eduHisdData: [],
      positionOrgMap: {},
      isRead: false,
      isHistory: false,
      historyDate: new Date(),
      lists: [],
      modal: false,
      resume: false,
      choose: null,
      sourceData: null,
      loading: {
        modal: false,
        lists: false
      },
      noteText: '',
      loadingFile: false,
      modalPhoto: false,
      files: [],
      src: null,
      showEditLog: false,
      showDetail: false,
      workerJobTitle: []
    }
  },
  computed: {
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    },
    workerTableType1 () {
      return this.$store.getters.sysConfig['workerTableType1']
    },
    showToolBox () {
      return ['export'].concat(['table-config'].filter(v => this.funAuth[v]).map(v => v.replace('table-', '')))
    },
    titleMenus () {
      let data = [{
        key: 'sort',
        label: '默认排序设置'
      },
      {
        key: 'history',
        label: '历史数据'
      },
      {
        key: 'add',
        label: '新增信息'
      },
      // {
      //   key: 'getWorkerEdit',
      //   label: '信息变更申请记录'
      // },
      {
        key: 'output',
        label: '导出'
      }].filter((v) => v.key === 'add' ? this.funAuth.add || this.funAuth.applyWorkerAdd : this.funAuth[v.key])
      let dataAll = this.filters ? [{
        key: 'show_all',
        label: '查看全部人员信息'
      }] : []
      return this.isHistory ? [
        { key: 'current', label: '当前数据' },
        { key: 'output', label: '导出' }
      ] : dataAll.concat(data)
    },
    funAuth () {
      return this.funAuthOther || this.$authFunsProxy
    },
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    baseColumns () {
      let data = this.baseColumns1.filter(v => this.needOrgName2 || v.field !== 'zg')
      data = data.filter(v => this.workerTableType1 || v.workerTableType1 !== true)
      data.forEach(v => {
        if (v.filters) {
          v.filters.push({
            value: '__null',
            label: '值为空'
          })
        }
      })
      return data
    },
    columnsSort () {
      return [
        { title: '姓名', field: 'name', sort: true},
        { title: '身份证号码', field: 'idNo', sort: true},
        {
          title: '默认排序',
          field: 'position',
          sort: true,
          dataType: Number,
          filterRange: true,
          render: (h, rowData) => {
            return h(SortNum, {
              props: {
                workerData: rowData
              },
              on: {
                sortNumChange: (sortNum) => {
                  rowData.position = sortNum
                  let list1 = this.lists.filter(v => v.position !== null)
                  let List2 = this.lists.filter(v => v.position === null)
                  list1.sort((a, b) => Number(a.position) - Number(b.position))
                  this.lists = list1.concat(List2)
                }
              }
            })
          }
        }
      ]
    },
    baseColumns1 () {
      let isHistory = this.isHistory
      return [
        { title: '工号', field: 'code', fixed: 'left', sort: true, width: 120 },
        { title: '机构名称', field: 'orgName', fixed: 'left', sort: true, width: 200 },
        { title: '机构名称2', field: 'zg', fixed: 'left', sort: true, width: 130 },
        { title: '姓名', field: 'name', fixed: 'left', sort: true, width: 120},
        { title: '性别', field: 'sex', sort: true, width: 120, filters: [{
          label: '男',
          value: '男'
        },
        {
          label: '女',
          value: '女'
        }]
        },
        { title: '民族', width: 120, field: 'clan', sort: true, filters: this.$store.getters.selectDatas['worker_clan_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        {
          title: '所属职务',
          field: 'positionIdText',
          width: 120,
          sort: true, filters: this.$store.getters.positionList.map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
        },
        { title: '兼职职务', width: 120,field: 'positionIdsText', sort: true, filters: this.$store.getters.positionList.map(v => {
          return {
            label: v.label,
            value: v.label
          }
        }),
        filterMethod (parm) {
          if (parm.value === '__null') {
            return [undefined, '', null].includes(parm.row.positionIdsText)
          }
          if (parm.row.positionIdsText) {
            let l = parm.row.positionIdsText.split(',')
            return l.includes(parm.value)
          } else {
            return false
          }
        }
        },
        {
          title: '默认展示机构',
          field: 'orgTypeDefault',width: 120, sort: true
        },
        {
          title: '出生年月', field: 'birth', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
          }
        },
        {
          title: '年龄', field: 'age', sort: true, dataType: Number, width: 120, filterRange: true
        },
        { title: '政治面貌', width: 120,field: 'politicalStatus', sort: true,
        filters: this.$store.getters.selectDatas['worker_political_status_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        { title: '身份证号码', field: 'idNo', sort: true, width: 184 },
        { title: '是否在编', width: 120,field: 'type', sort: true, filters: ['在编', '非在编'].map(v => {
          return {
            label: v,
            value: v
          }
        })},
        { title: '在岗状态',width: 120, field: 'jobTypeC', sort: true, filters: this.$store.getters.selectDatas['worker_zg_status'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '工龄', field: 'maritalStatus', sort: true, dataType: Number, width: 120, filterRange: true},
        { title: '第一学历', width: 120,field: 'dyxlqk', sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '第一学历毕业学校',width: 120, field: 'dyxlxx', sort: true, workerTableType1: true},
        { title: '第一学历专业',width: 120, field: 'dyxlzy', sort: true, workerTableType1: true},
        { title: '第一学历毕业时间', field: 'dibysj', width: 250, sort: true, dataType: Date, filterRange: true },
        { title: '最高学历', width: 120,field: 'zgxl', sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '最高学历毕业学校',width: 120, field: 'zgxx', sort: true, workerTableType1: true},
        { title: '最高学历专业',width: 120, field: 'zgzy', sort: true },
        { title: '最高学历毕业时间', field: 'zgbysj', width: 250, sort: true, dataType: Date, filterRange: true },
        { title: '是否港澳台及外籍人士',width: 120, field: 'graduatedSchool', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        {
          title: '参加工作时间', field: 'correctionDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-')
          }
        },
        {
          title: '进入本单位时间', field: 'entryDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-')
          }
        },
        { title: '人员类别',width: 120, field: 'jobType', sort: true, filters: this.$store.getters.selectDatas['worker_job_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否特殊人才',width: 120, field: 'agreementCode', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '入职方式',width: 120, field: 'employmentForm', sort: true, filters: this.$store.getters.selectDatas['employment_form'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '离职方式',width: 120, field: 'discipline', sort: true, filters: this.$store.getters.selectDatas['worker_resign_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '首次定岗类别',width: 120, field: 'srgwlb', sort: true, filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '首次定岗级别',width: 120, field: 'srgwjb', sort: true,
          filterMethod (parm) {
            return parm.row.srgwjb === parm.value
          }
        },
        { title: '首次定岗时间', field: 'srgwsj', width: 250, sort: true, dataType: Date, filterRange: true, },
        { title: '现任岗位类别',width: 120, field: 'xrgwlb', sort: true, filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '现任岗位层级',width: 120, field: 'xrgwTopLevel', sort: true, filters: [{value: '初级', label: '初级'}, {value: '中级', label: '中级'}, {value: '高级', label: '高级'}]},
        { title: '现任岗位职称级别',width: 120, field: 'xrgwMLevel', sort: true, filters: [{value: '员级', label: '员级'}, {value: '助理级', label: '助理级'}, {value: '中级', label: '中级'}, {value: '副高级', label: '副高级'}, {value: '正高级', label: '正高级'}]},
        { title: '现任岗位等级',width: 120, field: 'xrgwJobTitleTitle', sort: true,
          filterMethod (parm) {
            return parm.row.xrgwJobTitleTitle === parm.value
          }
        },
        { title: '现任岗位级别',width: 120, field: 'xrgwjb', sort: true },
        { title: '现任岗位时间', field: 'xrgwsj', width: 250, sort: true, dataType: Date, filterRange: true, },
        { title: '是否专业技术人员',width: 120, field: 'address', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '专业技术人员专业名称',width: 120, field: 'shuangjiantiao', sort: true },
        { title: '专业技术人员专业类别',width: 120, field: 'email', sort: true, filters: this.$store.getters.selectDatas['zyjsryzylb'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否卫生专业技术人员',width: 120, field: 'jobTitle', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '学历', field: 'education', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '学位', field: 'degree', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_edu_degree'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '籍贯', field: 'hometown', width: 120, sort: true },
        { title: '联系电话', field: 'phone', width: 120, sort: true },
        { title: '个人身份', field: 'sf',width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_identity_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        ...this.$store.getters.orgTypeList.map((v) => {
          return {
            title: '所属' + v.data.name + '机构',
            field: 'orgType_' + v.data.id,
            width: 120,
            sort: true,
            search: true
          }
        }),
        { title: '默认排序',width: 120, field: 'position', sort: true },
        {
          title: '操作', field: 'action', fixed: 'right', export: false, width: 230,
          render: (h, row) => {
            let data = [
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: () => {
                    this.sourceData = row
                    this.choose = Object.assign({}, row)
                    this.showDetail = true
                  }
                }
              }, '明细'),
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: () => {
                    this.isRead = true
                    this.setChoose(row)
                    this.eduHisdData = row.eduHisd
                    this.src = null
                    this.loadFile()
                  }
                }
              }, '简历'),
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: () => {
                    this.isRead = false
                    this.setChoose(row)
                  }
                }
              }, '修改'),
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: async () => {
                    let res = await this.$dialog.confirm('确定' + (!this.funAuth.del && this.funAuth.applyWorkerDel ? '申请' : '') + '删除么?')
                    if (res) {
                      if (this.funAuth.del) {
                        await workerRequest.del(row.id)
                        this.$notice.info({
                          title: '系统提示',
                          desc: '员工信息已删除'
                        })
                        this.loadData()
                        this.$store.dispatch('loadWorkerList', true)
                      } else {
                        await dataAppleRequest.add({
                          dataType: 'worker',
                          dataId: row.id,
                          type: 'del',
                          applyUserId: this.$store.getters.userInfo.id,
                          hisData: JSON.stringify(row, function (key, value) {
                            if (key === 'positionIds' && Array.isArray(value)) {
                              return value.join(',')
                            } else {
                              return value
                            }
                          }),
                          status: 'wait'
                        })
                        this.$notice.info({
                          title: '系统提示',
                          desc: '已删除申请，请等待审批处理'
                        })
                      }
                    }
                  }
                }
              }, '删除'),
            ]
            let newData = [
              h('fm-btn', {
                props: { text: true },
                style: { padding: 0, marginLeft: '4px' },
                on: {
                  click: () => {
                    this.print(row)
                  }
                }
              }, '打印')
            ]
            if (this.funAuth.allinfo) {
              newData.push(data[0])
            }
            // if (this.funAuth.resume) {
            //   newData.push(data[1])
            // }
            if (!isHistory && this.funAuth.edit) {
              newData.push(data[2])
            }
            if (!isHistory && this.funAuth.del) {
              newData.push(data[3])
            }
            return h('div', newData)
          }
        }
      ].map(v => {
        v.search = true
        return v
      })
    },
    orgTypeList () {
      return this.$store.getters.orgTypeList
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    },
    columnsLog () {
      return (columnsLogs.find(v => v.key === 'worker') || {columns: []}).columns
    },
    orgTypeIds () {
      return this.$store.getters.orgTypeList.map(v => v.data.id)
    },
    dataList () {
      let positionIds = null
      if (this.$route.query && Object.keys(this.$route.query).includes('positionIds')) {
        positionIds = this.$route.query.positionIds ? this.$route.query.positionIds.split(',') : []
      }
      return this.lists.filter(v => !positionIds || positionIds.includes(String(v.positionId)))
    },
    filters () {
      if (this.$route.query && Object.keys(this.$route.query).length) {
        return (item) => (
          (!this.$route.query.orgId || String(this.$route.query.orgId).split(',').includes(String(item.orgId)))
          &&
          (!this.$route.query.positionId || String(this.$route.query.positionId).split(',').includes(String(item.positionId)))
        )
      } else {
        return null
      }
    }
  },
  methods: {
    defaultOptionsFilterMethod (filterValue, columnValue) {
      return filterValue === columnValue
    },
    async print (row) {
      this.loading.lists = true

      // await loadScript('/static/pdfjs-2.12.313-dist/build/pdf.js')

      let replace = {
        CODE: row.id,
        ORG_NAME: row['orgType_' + this.$store.getters.orgTypeList[0].data.id],
        ORG_ROOM: row['orgType_' + this.$store.getters.orgTypeList[4].data.id],
        USER_NAME: row.name,
        USER_SEX: row.sex,
        USER_CLAN: row.clan,
        USER_HOMETOWN: row.hometown,
        USER_POLITICALSTATUS: row.politicalStatus,
        USER_EDU: row.dyxlqk,
        USER_BIRTH: (row.birth || '').substr(0, 10),
        USER_AGE: row.age,
        USER_AGREEMENTCODE: row.agreementCode,
        USER_graduatedSchool: row.graduatedSchool,
        USER_SF: row.sf,
        USER_PHONE: row.phone,
        USER_IDNO: row.idNo,
        USER_CODE: row.code,
        USER_ORGNAME: row.orgName,
        USER_POSITIONIDTEXT: row.positionIdText,
        USER_POSITIONIDSTEXT: row.positionIdsText,
        USER_TYPE: row.type,
        USER_JOBTYPEC: row.jobTypeC,
        USER_CORRECTIONDATE: (row.correctionDate || '').substr(0, 10),
        USER_MARITALSTATUS: row.maritalStatus,
        USER_ENTRYDATE: (row.entryDate || '').substr(0, 10),
        USER_EMPLOYMENTFORM: row.employmentForm,
        USER_JOBTYPE: row.jobType,
        USER_ADDRESS: row.address,
        USER_EMAIL: row.email,
        USER_SHUANGJIANTIAO: row.shuangjiantiao,
        USER_JOBTITLE: row.jobTitle,
        USER_DISCIPLINE: row.discipline
      }
      
      let draft = await workerRequest.getDetail({workerId: row.id})
      draft = draft.pop()
      if (draft && draft.workerEduDatas.length) {
        replace.EDU_HIS = draft.workerEduDatas.map((data) => {
          return `<tr><td>${data.edu || '-'}</td><td>${data.jyxs || '-'}</td><td>${(data.startDate || '-').substr(0, 10)} 至 ${(data.endDate || '-').substr(0, 10)}</td><td>${data.school || '-'}</td><td>${data.pro || '-'}</td></tr>`
        })
      } else {
        replace.EDU_HIS = '<tr><td colspan="5">暂无</td></tr>'
      }

      if (draft && draft.hisWorkDatas.length) {
        replace.WORK_HIS = draft.hisWorkDatas.map((data) => {
          return `<tr><td colspan="2">${data.orgName || '-'}</td><td colspan="2">${(data.startTime || '-').substr(0, 10)} 至 ${(data.endTime || '-').substr(0, 10)}</td><td>${data.jobTitle || '-'}</td></tr>`
        })
      } else {
        replace.WORK_HIS = '<tr><td colspan="5">暂无</td></tr>'
      }

      if (draft && draft.rewPunDatas.length) {
        replace.AWARD_HIS = draft.rewPunDatas.map((data) => {
          return `<tr><td colspan="2">${data.pzwj || '-'}</td><td>${data.pzdw || '-'}</td><td colspan="2">${(data.pzsj || '-').substr(0, 10)}</td></tr>`
        })
      } else {
        replace.AWARD_HIS = '<tr><td colspan="5">暂无</td></tr>'
      }

      if (draft && draft.trainDatas.length) {
        replace.TRAINING_HIS = draft.trainDatas.map((data) => {
          return `<tr><td colspan="2">${data.zbdw || '-'}</td><td>${data.type || '-'}</td><td>${data.jypx || '-'}</td><td>${(data.startDate || '-').substr(0, 10)} 至 ${(data.endDate || '-').substr(0, 10)}</td></tr>`
        })
      } else {
        replace.TRAINING_HIS = '<tr><td colspan="5">暂无</td></tr>'
      }

      if (draft && draft.workerJobTitleDatas.length) {
        replace.MOUNT_HIS = draft.workerJobTitleDatas.map((data) => {
          return `<tr><td>${data.jobTitleType || '-'}</td><td>${data.jobTitleTitle || '-'}</td><td colspan="2">${(data.startDate || '-').substr(0, 10)} 至 ${(data.endDate || '-').substr(0, 10)}</td><td>${data.hisName || '-'}</td></tr>`
        })
      } else {
        replace.MOUNT_HIS = '<tr><td colspan="5">暂无</td></tr>'
      }

      let img = row.headFileId ? await getImage([row.headFileId]) : []
      replace.USER_AVATAR = img.length ? '<img src="' + img[0] + '" style="display: block; margin: 0 auto; width: 2.5cm;" />' : '暂无证件照'
      
      img = await getImage([row.idCardBackFileId, row.idCardFrontFileId].filter(v => v))
      replace.ID_IMG = img.map(v => '<img style="margin: 0 auto; display: block; max-width: 100%;" src="' + v + '"/>')

      if (draft) {
        let certificates = [
          'workerCertificateProQuaDatas', // 专业技术资格证书
          'workerCertificateDoctorPraDatas', // 执业证书
          'workerCertificateDoctorQuaDatas', // 职业资格证书
          'workerCertificateProHireDatas', // 专业技术职务聘任证书
          'workerCertificateOtherDatas', // 其他证书
        ]
        for (let key of certificates) {
          replace[key.toUpperCase()] = []
          if (draft[key].length) {
            for (let item of draft[key]) {
              img = await getImage(item.fileIds ? item.fileIds.split(',') : [])
              if (img.length) {
                replace[key.toUpperCase()] = [...replace[key.toUpperCase()], ...img.map(v => '<img style="margin: 0 auto; display: block; max-width: 100%;max-height: 90vh;" src="' + v + '"/>')]
              }
            }
          }
        }
      }

      this.loading.lists = false

      this.$refs.print.print('resume', row.name + '员工档案', (tpl) => {
        Object.keys(replace).forEach(key => {
          /* eslint-disable no-useless-escape */
          const reg = new RegExp('((<\\!\\-\\-)?\\s*{{\\s*' + key + '\\s*}}\\s*(\\-\\->)?)', 'g')
          tpl = tpl.replace(reg, Array.isArray(replace[key]) ? replace[key].join('') : (replace[key] || '-'))
        })
        return tpl
      })
    },
    async loadFile () {
      this.src = null
      let path = '/worker/' + this.choose.id + '/'
      this.loadingFile = true
      try {
        let data = await fileRequest.get({
          path: path
        })
        this.files = data
        if (this.files.length > 0) {
          const fileData = await fileRequest.download({
            path: this.files[0].path
          })
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        console.log(error)
        this.files = []
        this.src = null
      }
      this.loadingFile = false
    },
    exportFileName () {
      return '员工信息' + this.$datetime.format(new Date(), 'YMD')
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (key) {
      if (key === 'show_all') {
        this.$router.replace({name: this.$route.name})
      } else if (key === 'add') {
        this.choose = {
          orgName: this.$store.getters.sysConfig['defaultOrgName'] || '',
          zg: this.$store.getters.sysConfig['defaultOrgName2'] || '',
        }
        this.sourceData = {}
        this.isRead = false
        this.modal = true
      } else if (key === 'getWorkerEdit') {
        this.showEditLog = true
      } else if (key === 'output') {
        this.exportData()
      } else if (key === 'current') {
        this.isHistory = false
        this.loadData()
      } else if (key === 'history') {
        this.isHistory = true
        this.loadData()
      } else if (key === 'sort') {
        this.sortModal = true
      }
    },
    exportData () {
      this.$refs.table.openExport()
    },
    async saveModal (type) {
      let vm = this.$refs['worker_form']
      if (vm && typeof vm.getFormData === 'function') {
        let data = vm.getFormData()
        if (data) {
          this.choose = data
        } else {
          return
        }
      } else {
        return
      }
      if (type === 'sq') {
        this.saveModalSp()
        return
      }
      this.loading.modal = true
      if (this.choose.id) {
        await workerRequest.update(this.choose.id, this.choose)
        this.$notice.success({
          title: '系统提示',
          desc: '员工信息已修改'
        })
      } else {
        let data = await workerRequest.add(this.choose)
        if (!this.choose.code) {
          await workerRequest.update(data.id, {code: ('000000'.slice(String(data.id).length) + data.id)})
        }
        this.$notice.success({
          title: '系统提示',
          desc: '员工信息已新增'
        })
      }
      this.$store.dispatch('loadWorkerList', true)
      this.loadData()
      this.loading.modal = true
      this.choose = null
      this.sourceData = null
      this.modal = false
    },
    getDiffKeys () {
      let diffData = []
      Object.keys(this.choose).forEach((key) => {
        let item = {
          key: key,
          update: this.choose[key],
          old: this.sourceData[key] || null
        }
        if (key === 'positionIds') {
          if (Array.isArray(item.update)) {
            item.update = item.update.join(',')
          }
          if (Array.isArray(item.old)) {
            item.old = item.old.join(',')
          }
          if ((item.update || item.old) && item.update !== item.old) {
            diffData.push(item)
          }
        } else if (item.update !== item.old && !['age', 'educationList'].includes(key)) {
          diffData.push(item)
        }
      })
      return diffData
    },
    async saveModalSp () {
      let editKeys = this.getDiffKeys()
      if (editKeys.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无数据变更，请重新修改数据。'
        })
        this.modal = false
        return
      }
      this.loading.modal = true
      
      let hisData = {}
      let newData = {}

      editKeys.forEach(item => {
        hisData[item.key] = item.old
        newData[item.key] = item.update
      })

      let data = {
        dataType: 'worker',
        type: this.choose.id ? 'edit' : 'add',
        applyUserId: this.$store.getters.userInfo.id,
        hisData: JSON.stringify(hisData),
        newData: JSON.stringify(newData),
        status: 'wait'
      }

      if (this.choose.id) {
        data.dataId = this.choose.id
      }

      await dataAppleRequest.add(data)

      this.$notice.info({
        title: '系统提示',
        desc: (this.choose.id ? '修改' : '新增') + '申请已提交。'
      })
      
      this.loading.modal = false
      this.choose = null
      this.sourceData = null
      this.modal = false
    },
    closeModal () {
      this.modal = false
      this.choose = null
      this.sourceData = null
    },
    setChoose (row) {
      this.sourceData = row
      this.choose = Object.assign({}, row)
      this[!this.isRead ? 'modal' : 'resume'] = true
    },
    dealPositionOrg (orgTree, orgList) {
      orgTree.forEach((v) => {
        if (v.positions && v.positions.length > 0) {
          v.positions.forEach(v2 => this.positionOrgMap[v2.id] = [v].concat(orgList))
        }
        if (v.child && v.child.length > 0) {
          this.dealPositionOrg(v.child, [v].concat(orgList))
        }
      })
    },
    dealOrgData (orgTree, orgList) {
      orgTree.forEach((v) => {
        orgList.push(v)
        if (v.child && v.child.length > 0) {
          this.dealOrgData(v.child, orgList)
        }
      })
    },
    loadPositionOrg () {
      let orgList = []
      let orgTree = this.$store.getters.orgTree
      this.dealPositionOrg(orgTree, [])
      this.dealOrgData(orgTree, orgList)
      this.orgTree = orgList
    },
    async loadWjt () {
      this.workerJobTitle = await workerJobTitleRequest.get()
    },
    async loadData () {
      this.loading.lists = true
      let wjt = this.isHistory ? this.workerJobTitle.filter(v => new Date(v.startDate) <= this.historyDate) : this.workerJobTitle
      let eduDatas = await workerEduRequest.get()
      let eduMap = {}
      eduDatas.filter(v => !this.isHistory || (v.endDate && new Date(v.endDate) <= this.historyDate)).forEach(v => eduMap[v.workerId] = eduMap[v.workerId] ? eduMap[v.workerId].concat(v) : [v])
      this.lists = await workerRequest.get()
      // if (this.jobTypeC) {
      //   this.lists = this.lists.filter(v => this.jobTypeC.includes(v.jobTypeC))
      // }
      if (this.isDiscipline === 'yes') {
        this.lists = this.lists.filter(v => v.discipline)
      } else if (this.isDiscipline === 'no') {
        this.lists = this.lists.filter(v => !v.discipline)
      }

      let now = this.isHistory ? this.historyDate : new Date()

      this.lists.forEach((v) => {
        v.positionIds = v.positionIds ? v.positionIds.split(',') : []
        v.positionIds = v.positionIds.map(v1 => Number(v1))
        v.age = getAge(v.birth, now)
        let eduHisd = eduMap[v.id] ? eduMap[v.id] : []
        v.eduHisd = eduHisd
        v.fEduHisd = eduHisd.find(v => v.type === '第一学历')
        v.lEduHisd = eduHisd.find(v => v.type === '最高学历')

        v.workerJobTitles = wjt.filter(v2 => v2.workerId === v.id)
        v.fWjt = v.workerJobTitles.length > 0 ? v.workerJobTitles[0] : null

        let cWorkerJobTitles = v.workerJobTitles.filter(v2 => !v2.endDate)
        v.lWjt = cWorkerJobTitles.length > 0 ? cWorkerJobTitles[cWorkerJobTitles.length - 1] : null


        if (v.positionId && this.positionOrgMap[v.positionId]) {
          this.positionOrgMap[v.positionId].filter(v3 => v3.orgTypeId).forEach((v2) => {
            let key = 'orgType_' + v2.orgTypeId
            let keyId = 'orgTypeValue_' + v2.orgTypeId
            if (keyId === v.zhuanzhi) {
              v.orgTypeDefault = v2.name
            }
            v[key] = v2.name
            v[keyId] = v2.id
          })
        }

        v.positionIdText = v.positionId && this.positionMap[v.positionId] ? this.positionMap[v.positionId] : null
        v.positionIdsText = v.positionIds && v.positionIds.length > 0 ? v.positionIds.map(v => this.positionMap[v]).filter(v => v).join(',') : null
        v.dyxlqk = v.fEduHisd ? v.fEduHisd.edu : null
        v.dyxlxx = v.fEduHisd ? v.fEduHisd.school : null
        v.dyxlzy = v.fEduHisd ? v.fEduHisd.pro : null
        v.dibysj = v.fEduHisd && v.fEduHisd.endDate ? v.fEduHisd.endDate.slice(0, 10) : null
        v.zgxl = v.lEduHisd ? v.lEduHisd.edu : null
        v.zgxx = v.lEduHisd ? v.lEduHisd.school : null
        v.zgzy = v.lEduHisd ? v.lEduHisd.pro : null
        v.zgbysj = v.lEduHisd && v.lEduHisd.endDate? v.lEduHisd.endDate.slice(0, 10) : null

        v.srgwlb = v.fWjt && v.fWjt.jobTitleType ? v.fWjt.jobTitleType : null
        v.srgwjb = v.fWjt && v.fWjt.type ? (v.fWjt.type === '医师' ? v.fWjt.yTitle : v.fWjt.hTitle) : null
        v.srgwsj = v.fWjt && v.fWjt.startDate ? v.fWjt.startDate.slice(0, 10) : null
        
        v.xrgwlb = v.lWjt && v.lWjt.jobTitleType ? v.lWjt.jobTitleType : null
        v.xrgwjb = v.lWjt && v.lWjt.type ? (v.lWjt.type === '医师' ? v.lWjt.yTitle : v.lWjt.hTitle) : null
        v.xrgwTopLevel = v.lWjt && v.lWjt.topLevel ? v.lWjt.topLevel : null
        v.xrgwMLevel = v.lWjt && v.lWjt.mLevel ? v.lWjt.mLevel : null
        v.xrgwJobTitleTitle = v.lWjt && v.lWjt.jobTitleTitle ? v.lWjt.jobTitleTitle : null
        v.xrgwsj = v.lWjt && v.lWjt.startDate ? v.lWjt.startDate.slice(0, 10) : null
      })
      this.lists = this.lists.filter((v) => {
        let pass = true
        if (this.isHistory && v.age && v.age < 0) {
          pass = false
        }
        return pass
      })
      let list1 = []
      let List2 = []
      this.lists.forEach(v => {
        if ( v.position !== null && v.position !== '' && !isNaN(v.position)) {
          list1.push(v)
        } else {
          List2.push(v)
        }
      })
      list1.sort((a, b) => Number(a.position) - Number(b.position))
      this.lists = list1.concat(List2)
      this.loading.lists = false
    }
  },
  async mounted () {
    await this.$store.dispatch('loadOrgTypeList')
    await this.$store.dispatch('loadPositionList')
    if (this.funAuth.get) {
      await this.$store.dispatch('loadOrgTree')
      this.loadPositionOrg()
      await this.loadWjt()
      this.loadData()
    }
    this.$tableColumnUpdate(this.$refs['table'], 'main')
  }
}
</script>
